// extracted by mini-css-extract-plugin
export var arrowL = "custom-styles-module--arrowL--c0198";
export var arrowR = "custom-styles-module--arrowR--b0474";
export var buttonsContainer = "custom-styles-module--buttonsContainer--80bab";
export var container = "custom-styles-module--container--fa431";
export var disabled = "custom-styles-module--disabled--daaed";
export var imageContainer = "custom-styles-module--imageContainer--76c7b";
export var overShadow = "custom-styles-module--overShadow--7e9d5";
export var refImage = "custom-styles-module--refImage--fba88";
export var scrollButton = "custom-styles-module--scrollButton--b5a74";
export var scrollButtonLeft = "custom-styles-module--scrollButtonLeft--d06d5";
export var scrollButtonRight = "custom-styles-module--scrollButtonRight--f1b56";
export var textContainer = "custom-styles-module--textContainer--c64b5";
export var textItem = "custom-styles-module--textItem--e3db6";
export var titleContainer = "custom-styles-module--titleContainer--84665";