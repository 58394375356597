import React from 'react'
import * as styles from '../custom.styles.module.css'

import Slider from '../../../components/newSlider/slider'


const index = () => {
    return (
        <section className={styles.pageContainerW}>

            <div>
                <Slider />
            </div>
        </section>
    )
}

export default index