import React, { useState, useRef, useEffect } from 'react';
import * as styles from './custom.styles.module.css';
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';



const references = [
    {
        name: '',
        src: '/reference-valvie/1 (1).jpg'
    },
    {
        name: '',
        src: '/reference-valvie/2 (1).jpg'
    },
    {
        name: '',
        src: '/reference-valvie/3 (1).jpg'
    },
    {
        name: '',
        src: '/reference-valvie/4 (1).jpg'
    },
    {
        name: '',
        src: '/reference-valvie/5 (1).jpg'
    },
    {
        name: '',
        src: '/reference-valvie/6 (1).jpg'
    },
]

const Slider = () => {
    const containerRef = useRef(null);
    const cardRef = useRef(null);

    const [scrollPosition, setScrollPosition] = useState(0);
    const [maxScrollPosition, setMaxScrollPosition] = useState(0);

    const handleScrollLeft = () => {
        containerRef.current.scrollTo({
            left: scrollPosition - cardRef.current.offsetWidth,
            behavior: 'smooth',
        });
    };

    const handleScrollRight = () => {
        containerRef.current.scrollTo({
            left: scrollPosition + cardRef.current.offsetWidth,
            behavior: 'smooth',
        });
    };

    const handleScroll = () => {
        setScrollPosition(containerRef.current.scrollLeft);
        setMaxScrollPosition(
            containerRef.current.scrollWidth - containerRef.current.offsetWidth
        );
    };

    useEffect(() => {
        setMaxScrollPosition(
            containerRef.current.scrollWidth - containerRef.current.offsetWidth
        );
    }, []);

    return (
        <div style={{ paddingTop: '2rem', paddingInline: '1.2rem', paddingBottom: '2rem' }}>
            <div className={styles.container} id="referenzen">
                {/*  <div className={styles.titleContainer}>
                    <h2>Projects</h2>
                </div> */}

                <div className={styles.sliderContainer}>
                    <div
                        className={`${styles.textContainer}`}
                        ref={containerRef}
                        onScroll={handleScroll}
                    >
                        {references.map((reference, index) => (
                            <div className={styles.textItem} key={index} ref={cardRef}>
                                <div className={styles.overShadow}></div>
                                <div className={styles.imageContainer}>
                                    <img
                                        className={styles.refImage}
                                        src={reference.src}
                                        width={300}
                                        height={400}
                                        alt={reference.name}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className={styles.buttonsContainer}>
                    <button
                        className={`${styles.scrollButton} ${styles.scrollButtonLeft} ${scrollPosition === 0 ? styles.disabled : ''}`}
                        onClick={handleScrollLeft}
                    >
                        <IoIosArrowBack className={styles.arrowL} />
                    </button>

                    <button
                        className={`${styles.scrollButton} ${styles.scrollButtonRight} ${scrollPosition >= maxScrollPosition ? styles.disabled : ''}`}
                        onClick={handleScrollRight}
                    >
                        <IoIosArrowForward className={styles.arrowR} />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Slider;